import { useEffect } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import type { IconNames } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

type Position = 'absolute' | 'relative' | 'fixed' | 'initial' | 'sticky'

type FeedbackStyles = {
  background: string
  iconColor: string
  icon: IconNames
}

type FeedbackTypes = {
  success: FeedbackStyles
  warning: FeedbackStyles
  error: FeedbackStyles
  info: FeedbackStyles
}

export interface ITooltipFeedback {
  closeIcon?: boolean
  customHeight?: string
  errorCode?: string
  gap?: string
  iconMargin?: string
  linkText?: string
  maxWidth?: string
  nestedTextComponent?: React.ReactElement
  paddingTooltip?: string
  position?: Position
  positionContainer?: Position
  text: string
  truncate?: number
  type: 'success' | 'error' | 'warning' | 'info'
  width?: string
  onClickLink?: () => void
  onClose?: () => void
}

export const TooltipFeedback = ({
  customHeight = '60px',
  errorCode,
  gap = '8px',
  iconMargin = '0 4px 0 0',
  linkText = '',
  maxWidth = '343px',
  nestedTextComponent = null,
  paddingTooltip = '0 8px',
  position = 'sticky',
  positionContainer = 'sticky',
  text = 'Lista eliminada exitosamente',
  truncate = 74,
  type = 'success',
  width = '343px',
  onClose,
  onClickLink,
  closeIcon = true
}: ITooltipFeedback): React.ReactElement => {
  const feedbackStyles: FeedbackTypes = {
    success: {
      background: getGlobalStyle('--color-feedback-success-bg'),
      iconColor: getGlobalStyle('--color-feedback-success-light'),
      icon: 'CheckCircle'
    },
    warning: {
      background: getGlobalStyle('--color-feedback-warning-bg'),
      iconColor: getGlobalStyle('--color-feedback-warning-light'),
      icon: 'WarningFilled'
    },
    error: {
      background: getGlobalStyle('--color-feedback-error-bg'),
      iconColor: getGlobalStyle('--color-feedback-error-light'),
      icon: 'InfoFill'
    },
    info: {
      background: getGlobalStyle('--color-feedback-info-bg'),
      iconColor: getGlobalStyle('--color-feedback-info'),
      icon: 'InfoFill'
    }
  }

  useEffect(() => {
    const closeTimeout = setTimeout(() => {
      if (onClose) onClose()
    }, 3000)

    return () => {
      return clearTimeout(closeTimeout)
    }
  }, [])

  return (
    <Tooltip
      alignAllItems='center'
      background={feedbackStyles[type].background}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-icons-black')}
      closeIcon={closeIcon}
      display='flex'
      height={customHeight}
      iconMargin={iconMargin}
      id={getBemId('tooltipFeedback', 'tooltip', type)}
      maxWidth={maxWidth}
      onClick={onClose}
      padding={paddingTooltip}
      position={position}
      positionContainer={positionContainer}
      textComponent={
        <Container zIndex='1000'>
          <Row
            alignItems='center'
            gap={gap}
          >
            <Column
              id={getBemId('tooltipFeedback', 'icon', type)}
              maxWidth='max-content'
            >
              <Icon
                color={feedbackStyles[type].iconColor}
                customSize={20}
                name={feedbackStyles[type].icon}
              />
            </Column>
            <Column margin='0 8px 0 0'>
              <Text
                color='black'
                display='inline'
                fontSize='md'
                fontWeight='medium'
                id={getBemId('tooltipFeedback', 'text', type)}
                truncate={truncate}
                type='span'
              >
                {text}
                {errorCode && (
                  <>
                    <Text
                      color='gray'
                      display='inline'
                      fontSize='2xs'
                      type='span'
                    >
                      &nbsp;({errorCode})
                    </Text>
                  </>
                )}
              </Text>

              {nestedTextComponent}
            </Column>
            {linkText && (
              <Column
                margin='0 8px 0 0'
                maxWidth='15%'
              >
                <Text
                  clickable='pointer'
                  color='success'
                  fontSize='sm'
                  fontWeight='semibold'
                  id={getBemId('tooltipFeedback', 'linkText', type)}
                  onClick={onClickLink}
                >
                  {linkText}
                </Text>
              </Column>
            )}
          </Row>
        </Container>
      }
      type='bottom'
      width={width}
    />
  )
}
